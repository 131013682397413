import React from "react"
import { Link } from "gatsby"

import Image from "../../image"

const Card = ({ img, title, content, col__color }) => {
  return (
    <div className="ourServices-card">
      <div className="d-flex justify-content-start align-items-center">
        <Image className="card-logo" src={img}></Image>
        <p className="ourServices-card-title">{title}</p>
      </div>
      <hr
        className="underline"
        style={{
          backgroundColor: `${col__color}`,
        }}
      ></hr>
      <ul className="card-list pl-4 mb-0">
        {content.map(item => (
          <li>
            <Link to={item.link}>{item.name}</Link>
          </li>
        ))}
      </ul>
      {/*<Link className="view-more-btn pl-4 mb-2 d-flex" type="button" to="/all-categories">
        + more
      </Link>*/}
    </div>
  )
}

export default Card
