import React from "react";
import { Link } from "gatsby";

//import BackgroundImage from "../background-image";
import Image from "../image";
import { Row, Col } from "reactstrap";

const Hero = () => {
  return (
    // <div className="container-fluid home-hero-container p-0">
    //   <BackgroundImage className="bgImage" src="hero.png">
    //     <div className="row content-container text-white">
    //       <div className="col-xs-12 col-sm-12 my-2">
    //         <h1>We help you buy smart</h1>
    //       </div>
    //       <div className="col-xs-12 col-sm-12 my-2">
    //         <h2>Make smart decision</h2>
    //       </div>
    //       <div className="col-xs-12 col-sm-12 mt-3">
    //         <p>
    //           Making important purchases for your business is a tough task. You
    //           could call individual companies and fight your way through a maze
    //           of recordings, but that takes time and time is money. Let Shop Biz
    //           Services quickly find the right seller for your needs who will
    //           compete to win your business.
    //         </p>
    //       </div>
    //       <div className="col-xs-12 col-sm-12 my-2">
    //         <Link className="btn-main" type="button" to="/all-categories">
    //           Find out more
    //         </Link>
    //       </div>
    //     </div>
    //   </BackgroundImage>
    // </div>
    <div className="banner">
      <Row className="m-0 h-100 home-hero-container">
        <Col
          md={7}
          className="h-100 content-container d-flex flex-column justify-content-center px-2 px-md-5 text-center text-light  text-md-left"
        >
          <h1 className="banner-title line-height-4 letter-spacing-2">
            We Help You Buy Products!
          </h1>

          <h2 className="banner-title line-height-4 letter-spacing-2">
            Make fast decision
          </h2>
          <p>
            Making important purchases for your business is a tough task. You
            could call individual companies and fight your way through a maze of
            recordings, but that takes time and time is money. Let Vetementco
            quickly find the right products for you.
          </p>
          <div className="inline mt-3">
            {/* <Link to="/services">
            <Button color="warning mr-2">Services</Button>
          </Link>
          <Link to="/#contact-us">
            <Button>Contact</Button>
          </Link> */}
            <Link className="" to="/all-categories">
              <button className="btn btn-primary">Find out more</button>
            </Link>
          </div>
        </Col>
        <Col md={5} className="d-md-flex align-items-center d-none">
          <div className="banner-image-wrapper">
            <Image src="hero2.png" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Hero;
