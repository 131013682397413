import React from "react"

import { SEO, Layout } from "../components"

import Hero from "../components/home/hero"
import HowItWorks from "../components/home/howItWorks"
import OurServices from "../components/home/ourServices"
import CallToAction from "../components/home/call-to-action"
import ContectUS from "../components/home/contect-us"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" description="" />
    <Hero />
    <HowItWorks />
    <OurServices />
    <CallToAction />
    <ContectUS />
  </Layout>
)

export default IndexPage
