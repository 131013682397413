import React from "react";
import Image from "../image";
import Card from "./ourServicesComponents/cards";

const OurServices = () => {
  const servicesList = [
    [
      {
        link: "/",
        name:
          "Front Goat leather Backside Split Leather Cuff with Split Leather",
      },
      {
        link: "/",
        name: "All Split leather Cuff with Rubberized Zean cloth",
      },

      {
        link: "/",
        name: "All Split leather Cuff with Split Leather",
      },
      {
        link: "/",
        name:
          "Front with Split leather Backside with special Fireproof silver fabric Piping all around",
      },
    ],
    [
      {
        link: "/",
        name: "Motorbike Suit",
      },
      {
        link: "/",
        name: "Motorbike Shoes",
      },
      {
        link: "/storage-containers",
        name: "",
      },
    ],
    [
      {
        link: "/",
        name: "Men Fashion Jackets",
      },
      {
        link: "/",
        name: "",
      },
      {
        link: "/",
        name: "",
      },

      {
        link: "/",
        name: "",
      },
    ],
    [
      {
        link: "/",
        name: "fitness_wears",
      },
      {
        link: "/",
        name: "",
      },
      {
        link: "/",
        name: "",
      },

      {
        link: "/",
        name: "",
      },
    ],
  ];
  return (
    <div className="container our-services">
      <div className="row d-flex justify-content-center align-items-center flex-column my-4">
        <div className="col d-flex justify-content-center">
          <Image className="icon w-100" src="our-services-img.png" />
        </div>
        <div className="col d-flex justify-content-center">
          <p className="main-heading">Our Services</p>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-lg-6">
          <Card
            title="Gloves Collections"
            img="pro__logo.png"
            col__color="#003049"
            content={servicesList[0]}
          />
        </div>
        <div className="col-xs-12 col-lg-6">
          <Card
            title="MotoBike Garments"
            img="home__logo.png"
            col__color="#D62828"
            content={servicesList[1]}
          />
        </div>
        <div className="col-xs-12 col-lg-6">
          <Card
            title="Leather Fashion"
            img="constructin__logo.png"
            col__color="#F77F00"
            content={servicesList[2]}
          />
        </div>
        <div className="col-xs-12 col-lg-6">
          <Card
            title="Sports Wear"
            img="health__logo.png"
            col__color="#FCBF49"
            content={servicesList[3]}
          />
        </div>
      </div>
    </div>
  );
};

export default OurServices;
