import React from "react";
import Image from "../image";

const CallToAction = () => {
  return (
    <React.Fragment>
      <div className="container-fluid call-to-action-main py-3 mt-3">
        <div className="call-to-action-content">
          <p className="call-to-action-heading mb-0">
            We Are Always Interesting In Hearing From You
          </p>
          <p className="call-to-action-content-sub">
            Whether it be questions, comments or orders - let us know what you
            think.
          </p>
        </div>
      </div>

      <div className="container call-to-action-subsection d-flex flex-column py-3">
        <div className="row mb-5 mt-3">
          <div className="col-xs-12 col-md-6 d-flex justify-content-center flex-column">
            <h6 className="">
              We will guide you through the key questions to consider.
            </h6>
            <p className="">
              Making important purchases for your business is a tough task. You
              could call individual companies and fight your way through a maze
              of recordings, but that takes time and time is money. Let
              Vetementco quickly find the right products for you.
            </p>
          </div>
          <div className="col-xs-12 col-md-6">
            <Image
              className="cal-to-action-img-1"
              src="img-about-us-1.png"
            ></Image>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-xs-12 col-md-6 order-2 order-md-1">
            <Image
              className="cal-to-action-img-2"
              src="img-about-us-2.png"
            ></Image>
          </div>
          <div className="col-xs-12 col-md-6 d-flex justify-content-center flex-column order-1 order-md-2">
            <h6 className="">Looking to fill your sales pipeline?</h6>
            <p className="">
              We’re always looking for quality sellers. Our lead generation
              programs have helped thousands of clients close more business.
              Connect with pre-screened, in-market buyers today.
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CallToAction;
